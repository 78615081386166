const { loadable } = require('nordic/lazy');

const UnknownComponent = require('../../../appearance/unknownComponent').default;

/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../../../appearance/document').default;
const Layout = require('../../../appearance/layout').default;

const ShoppingInfoDesktop = loadable(() => import('../../../appearance/shoppingInfo/desktop'));
const ShoppingInfoMobile = loadable(() => import('../../../appearance/shoppingInfo/mobile'));
const Row = loadable(() => import('../../../appearance/row'));
const Slider = loadable(() => import('../../../appearance/slider'));
const SectionText = loadable(() => import('../../../appearance/sectionText'));
const DataPrivacyInfo = loadable(() => import('../../../appearance/dataPrivacyInfo'));
const ContentList = loadable(() => import('../../../appearance/contentList'));
const Video = loadable(() => import('../../../appearance/videoAndText'));
const CategoryCarousel = loadable(() => import('../../../appearance/categoryCarousel'));
const ContactForm = loadable(() => import('../../../appearance/contactForm'));
const ContactInfo = loadable(() => import('../../../appearance/contactInfo'));
const AdhesionContractInfo = loadable(() => import('../../../appearance/adhesionContractInfo'));
const BankConditionsInfo = loadable(() => import('../../../appearance/bankConditionsInfo'));
const CancelBuyInfo = loadable(() => import('../../../appearance/cancelBuyInfo'));
const PaymentMethodsDesktop = loadable(() => import('../../../appearance/paymentMethods/desktop'));
const PaymentMethodsMobile = loadable(() => import('../../../appearance/paymentMethods/mobile'));
const SecondaryBanner = loadable(() => import('../../../appearance/secondaryBanner'));
const BuyerInfo = loadable(() => import('../../../appearance/buyerInfo'));
const BadgeCarousel = loadable(() => import('../../../appearance/badgeCarousel'));
const ProductsCarouselV2 = loadable(() => import('../../../appearance/productsCarouselV2'));
const ProductsCarouselV3 = loadable(() => import('../../../appearance/productsCarouselV3'));
const TabWithItems = loadable(() => import('../../../appearance/tabWithItems'));
const MshopItem = loadable(() => import('../../../appearance/item'));
const NewsLetterModal = loadable(() => import('../../../appearance/newsLetterModal'));
const NotFoundPage = loadable(() => import('../../../appearance/notFoundCCP'));
const RichText = loadable(() => import('../../../appearance/richText'));
const HowBuyInfo = loadable(() => import('../../../appearance/howBuyInfo'));
const LegalNoticesInfo = loadable(() => import('../../../appearance/legalNoticesInfo'));
const ProtectedPurchaseInfo = loadable(() => import('../../../appearance/protectedPurchaseInfo'));
const DebugInfo = loadable(() => import('../../../appearance/debugInfo'));
const CategoryGallery = loadable(() => import('../../../appearance/categoryGallery'));
const CategoryLabelImage = loadable(() => import('../../../appearance/categoryLabelImage'));
const CustomComponent = loadable(() => import('../../../appearance/customComponent'));

const Grid = require('../../../appearance/grid').default;

const ProductsGalleryV2Mobile = require('../../../appearance/productsGalleryV2/mobile').default;
const ProductsGalleryV2Desktop = require('../../../appearance/productsGalleryV2/desktop').default;
const ProductsGalleryV3 = require('../../../appearance/productsGalleryV3').default;

const nonEditableComponentList = (features) => {
  const isAddToCartDisabled = features?.includes('add-to-cart-custom-disabled');
  return {
    defaultComponent: UnknownComponent,
    /**
     * All the available components
     */
    nonEditables: {
      Document,
      Layout,
      Row,
      ContactForm,
      ContactInfo,
      Slider,
      Carousel: BadgeCarousel,
      NewsLetterModal,
      HowBuyInfo,
      CancelBuyInfo,
      BankConditionsInfo,
      DataPrivacyInfo,
      LegalNoticesInfo,
      AdhesionContractInfo,
      CategoryCarouselV2: CategoryCarousel,
      RichText,
      PaymentMethods: {
        mobile: PaymentMethodsMobile,
        desktop: PaymentMethodsDesktop,
      },
      ShoppingInfo: {
        mobile: ShoppingInfoMobile,
        desktop: ShoppingInfoDesktop,
      },
      ContentList,
      BuyerInfo,
      TabbedCarousel: {
        mobile: isAddToCartDisabled ? ProductsCarouselV2 : ProductsCarouselV3,
        desktop: isAddToCartDisabled ? ProductsCarouselV2 : ProductsCarouselV3,
      },
      TabWithItems,
      MshopItem,
      CollectionGrid: {
        displayAs: {
          original: {
            mobile: isAddToCartDisabled ? ProductsGalleryV2Mobile : ProductsGalleryV3,
            desktop: isAddToCartDisabled ? ProductsGalleryV2Desktop : ProductsGalleryV3,
          },
          material: {
            mobile: isAddToCartDisabled ? ProductsGalleryV2Mobile : ProductsGalleryV3,
            desktop: isAddToCartDisabled ? ProductsGalleryV2Desktop : ProductsGalleryV3,
          },
          default: {
            mobile: ProductsGalleryV2Mobile,
            desktop: ProductsGalleryV2Desktop,
          },
        },
      },
      CategoryGallery,
      CategoryLabelImage,
      SectionText,
      Video,
      DebugInfo,
      Grid,
      BannerSplinter: SecondaryBanner,
      NotFoundPage,
      ProtectedPurchaseInfo,
      CustomComponent,
    },
    editables: {},
  };
};

/**
 * EXPORTS
 */
module.exports = nonEditableComponentList;
